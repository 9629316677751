@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.container {
  min-height: 100dvh;
  height: 100vh;
  width: 470px;
  font-family: "Roboto", sans-serif;
  background-image: linear-gradient(#5ec3e7, #fff);
  /* position: relative;
  z-index: 1; */
}
/* .container {
    content: "";
    position: absolute;
    background-image: url(/big-cash-html/images/Vector.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 640px;
    height: 100%;
    left: -100px;
    z-index: -1;
} */
.container.white-bg {
  box-shadow: 0 0 10px #ccc;
  background: #fff;
  min-height: 100dvh;
}
.score-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 30px 2px 30px;
  margin-bottom: 20px;
}

.score-item {
  text-align: center;
}

.rounded-black-div,
.rounded-yellow-div {
  width: 150px;
  height: 50px;
  border-radius: 30px;
  background-color: black;
  margin: 6px auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}

.rounded-yellow-div {
  background-color: #f9e52a;
}

.score-item span {
  display: block;
  /* margin-top: ; */
  /* font-weight: bold; */
}

.score_text_yellow {
  font-size: 17px;
  color: black;
}

.score_text_black {
  font-size: 17px;
  color: white;
}
.center-logo {
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 auto;
  top: 45%;
  position: relative;
}
.intro-logo {
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 20%;
}

.intro-content {
  text-align: center;
  margin: 10% 0 0;
}
.intro-content h1 {
  font-size: 40px;
  color: #2b368a;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
.intro-content p {
  font-size: 20px;
  margin: 20px 0;
  color: #7a7a7c;
  font-weight: 400;
}
.intro-content img {
  animation: shake 2s;
  animation-iteration-count: unset;
}
.cus-header {
  margin: 20px 0;
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto";
  color: #2b368a;
  display: block;
  float: left;
  width: 100%;
}
.container-area {
  min-height: 100dvh;
  width: 100%;
  float: left;
  display: block;
}

.container-area a {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 26px;
}
a:hover {
  color: #fff;
  text-decoration: none;
}
.score-area .img-user {
  position: relative;
  top: -75px;
  margin: 0 auto;
  margin-bottom: -75px;
}
.score-area {
  background: #5bc2e7;
  margin: 60px 0 0;
  text-align: center;
  height: 100%;
  display: grid;
  padding: 30px 10px;
  border-radius: 0px 0px 50px 50px;
  margin-bottom: 30px;
  padding-bottom: 60px;
}
.big-font {
  margin: 30px 0px;
  font-size: 20px;
  text-align: center;
  color: #2b368a;
}
.user-score {
  background: url(../Images/star-bg.png);
  display: block;
  margin-bottom: 10px;
  background-size: auto;
  padding: 51px;
  background-repeat: no-repeat;
  background-position: center;
}
.blue-bg {
  display: inline-block;
  color: #fff;
  padding: 10px;
  background: #2b368a !important;
  border-radius: 13px;
  height: 83px;
  font-size: 14px;
  font-weight: 700;
  width: 59%;
  position: relative;
  top: 30px;
}
.blue-bg:before {
  content: "";
  position: absolute;
  background-image: url("../Images/star-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 210px;
  height: 200px;
  left: -12px;
  top: -92px;
}
.user-score {
  background: url("../Images/star-bg.png");
  display: block;
  margin-bottom: 10px;
  background-size: auto;
  padding: 51px;
  background-repeat: no-repeat;
  background-position: center;
}
.container-area-span {
  margin-top: -57px;
  margin-bottom: 20px;
}
.best-score {
  color: #fff;
  padding: 10px;
  background: #2b368a !important;
  border-radius: 13px;
  height: 100px;
  font-size: 14px;
  font-weight: 700;
}
.new_btn a {
  display: inline-block;
}
.new_btn {
  margin-top: -40px;
}
.score-area h3 {
  font-family: "Roboto";
  color: #2b368a;
  font-weight: 800;
  font-size: 20px;
}
.blue-row {
  background: #2b368a;
  display: block;
  width: 100%;
  float: left;
  font-size: 11px;
  padding: 8px 0;
  color: #fff;
  box-shadow: 0 0 10px #4f4f4f;
  text-align: center;
}

.rating-row {
  width: 100%;
  float: left;
  padding: 10px 0;
  text-align: center;
}

.white-bg-shadow {
  width: 100%;
  box-shadow: 0 0 10px #0000005e;
  display: block;
  float: left;
  padding: 0 2px;
  padding-bottom: 50px;
  border-radius: 0 0 45px 45px;
  background-color: #ffffff;
}
.cus-score-btn-blue {
  text-align: center;
  margin-bottom: 20px;
}
.cus-score-btn-blue {
  text-align: center !important;
  margin-bottom: 20px;
  display: block !important;
  float: left;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 29px;
}
.light-blue-bg {
  background: #ffffff !important;
}
.border-winner {
  border-bottom: 1px solid #00263a;
}

.border-winner .col-md-5.col-xs-5 {
  border-right: 1px solid #00263a;
  padding-left: 0;
}

.border-winner div {
  padding: 4px;
  font-weight: 700;
  font-size: 15px;
}
.rating-btn a {
  text-align: center;
  background: #fff;
  padding: 5px 15px;
  display: inline-block;
  margin: 0 auto;
  border-radius: 7px;
  min-width: 134px;
  margin: 20px 0 0;
  box-shadow: 0 0 10px #00263a;
  color: #00263a;
  font-weight: 800;
}
.padding-low {
  padding-bottom: 30px;
}
.rating-btn .col-md-6.col-xs-6 {
  text-align: center;
}
.cus-score-btn-blue a {
  background: #2b368a !important;
  padding: 12px 16px;
  border-radius: 25px;
  font-size: 18px;
  color: #5bc2e7;
  font-weight: bold;
}
.border-row {
  border: 3px solid #00263a;
  padding: 5px 0;
  border-radius: 11px;
  font-weight: 500;
  /* width: 100%; */
}
.score-area p {
  font-size: 15px;
  color: #2b368a;
}
.cus-btn-blue {
  font-weight: 700;
  background: #2b368a !important;
  display: table;
  padding: 10px 42px;
  border-radius: 33px;
  font-size: 24px;
  color: #5bc2e7;
  box-shadow: 0 0 10px #00000075;
  font-family: "Roboto";
  margin: 0px auto;
  margin-bottom: 20px;
}
.cus-btn-blue:hover {
  color: #fff;
}
.game-area {
  display: block;
  float: left;
  width: 100%;
  margin-top: 25px;
}
.game-area .col-md-6.col-xs-6 {
  margin-bottom: 20px;
}
.contineu_game_img_padding {
  margin-bottom: 50px;
  display: flex;
  padding: 20px;
  color: #fff;
}
.contineu_text,
.contineu_img,
.contineu_btn {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  text-align: left;
}
.contineu_btn {
  align-items: end;
}

.contineu_text a {
  background: #5bc2e7;
  padding: 5px 19px;
  border-radius: 50px;
  color: #00263a;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin: 0px;
}
.contineu_btn a {
  background: #fff;
  color: #2b368a;
  padding: 4px 8px 4px 8px;
  border-radius: 30px;
  line-height: 12px;
  font-size: 10px;
  margin: 0;
}
.contineu_img img {
  margin-left: 40px;
  width: 100%;
}
.page-icon img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.cus-game img {
  margin-top: -66px;
  border-radius: 50%;
}
.cus-game {
  text-align: center;
  background: #2b368a !important;
  padding: 26px 7px;
  border-radius: 18px;
  color: #fff;
  position: relative;
}
span.cus-game-span {
  padding: 10px 14px;
  border-radius: 20px;
  color: #00263a;
}
.contineu_game_img {
  position: relative;
  z-index: 1;
  background: #2b368a;
  border-radius: 16px;
}
.footer_container p {
  width: 470px;
}
.contineu_game_img:before {
  content: "";
  position: absolute;
  background-image: url("../Images/Vector\ 23.png");
  height: 169px;
  width: 199px;
  background-repeat: no-repeat;
  z-index: -1;
  background-size: cover;
  right: 0px;
  border-radius: 0px 14px 14px 0px;
}
.cus-game img {
  margin-top: -66px;
}
span.cus-game-span img {
  margin: unset;
}
.cus-game h4 {
  font-size: 20px;
  font-family: "Roboto";
  margin: 20px 0 30px;
  display: block;
}

.cus-game a {
  background: #5bc2e7;
  padding: 10px 16px;
  border-radius: 15px;
  color: #00263a;
  font-size: 20px;
  font-weight: 500;
}
.cus-game a img {
  margin: unset;
}
.cus-menu {
  float: right;
  display: inline-block;
  text-transform: capitalize;
  border: 2px solid #fff;
  padding: 5px 19px 5px 15px;
  border-radius: 44px;
  color: #fff;
  margin-right: 0;
  font-size: 16px;
  line-height: 25px;
}
.cus-menu:hover {
  color: #fff;
  text-decoration: none;
}
.page-icon {
  padding: 5px 0;
}

.cus-menu img {
  height: auto;
  margin-right: 6px;
  top: -1px;
  position: relative;
  width: 22px;
}
.info-icon {
  text-align: right !important;
  float: right;
  width: 100%;
  display: block;
  margin: 7px 10px 10px 0px !important;
}
.footer-menu {
  display: block;
  width: 100%;
  background: #2b368a !important;
  float: left;
  background-size: 100%;
  text-align: center;
  padding: 10px 10px 20px 10px;
  margin-bottom: 15px;
  background-position: center;
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  border-radius: 30px 30px 60px 60px;
  position: sticky;
}
.footer-menu::before {
  content: "";
  position: absolute;
  background-image: url("../Images/Rectangle\ 2.png");
  width: 25%;
  height: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  left: 37%;
  bottom: 7px;
}
.footer-menu .col-md-3.col-xs-3 {
  padding: 0;
}
.footer-menu a {
  color: #fff;
  font-size: 11px;
  display: block;
}

.footer-menu img {
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5px;
}
.container.white-bg {
  height: 100%;
  background-image: linear-gradient(#5ec3e7, #fff) !important;
  position: relative;
  z-index: 1;
}
.container.white-bg:before {
  content: "";
  position: absolute;
  background-image: url(http://localhost:3000/static/media/Vector.463b522f5d7d0b80bf08.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 2px;
  z-index: -1;
  background-position: center;
}
.container {
  position: relative;
  z-index: 1;
}
.container:before {
  content: "";
  position: absolute;
  background-image: url("../Images/Vector.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 640px; */
  height: 100%;
  left: -100px;
  z-index: -1;
}
.intro-screen {
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 auto;
  top: 45%;
  position: relative;
}
a.active {
  color: #5bc2e7;
}
.navbar-collapse {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100% !important;
  max-width: 100%;
  min-height: 100vh;
  padding: 0;
  background-color: #fff;
  transition: all 0.3s ease !important;
  transform: translateX(-100%);
  visibility: hidden;
  opacity: 0;
  display: flex !important;
  align-items: center;
  pointer-events: none;
  border: none;
  overflow: hidden !important;
}
.navbar-collapse .menu_inner {
  background-color: #2b368a !important;
  border-radius: 40px 0 0 40px;
  margin-left: auto;
  padding: 30px 30px 20px;
  flex: 1 1;
  max-height: 100%;
  overflow: auto;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.arrow_btn img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}
.navbar-collapse.in {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
  pointer-events: unset !important;
  position: absolute;
  top: 0;
}
.Header_verification__Iec-k img:nth-child(1) {
  margin-top: 110px;
}
.menu_inner .menu_logo img {
  /* margin: 0 auto; */
  height: 100px;
}
.menu_inner h2 {
  font-style: normal !important;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #fff;
  margin-top: 14px;
  font-size: 25px;
  padding-left: 4px;
}
.menu_inner ul.nav.navbar-nav {
  margin: 0;
  flex: 1;
}
.menu_inner ul.nav.navbar-nav > li > a {
  line-height: normal;
  padding: 0;
  display: flex;
  align-items: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.success {
  text-align: center;
  position: relative;
  top: 37%;
}
.success_content h2 {
  margin-top: 50px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #2b368a;
}
.success_content p {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 30px;
  color: #2b368a;
}
.success_content a {
  background: #e92230;
  padding: 12px 90px;
  border-radius: 5px;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  display: inline-block;
  margin-top: 20px;
}
.econet {
  margin-top: 110px;
  padding-bottom: 20px;
}
.econet p {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  font-weight: 300;
  margin-top: 20px;
  color: #7a7a7c;
}
.succes_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.succes_logo .number {
  border: 1px solid #2b368a;
  width: 50px;
  height: 50px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  color: #2b368a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.number:nth-child(3) {
  border-color: #e92230;
}
.contect_num {
  text-align: justify;
  margin-bottom: 50px;
}
.contect_num h2 {
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}
.contect_num p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b368a;
}
.my-terms-div table {
  width: 100% !important;
}
.verification {
  top: 15%;
}
.num_field a:nth-child(1) {
  padding: 12px 23px;
  background: #fff;
  color: #7a7a7c;
  font-size: 14px;
  font-weight: 400;
}
.login_btn {
  border-bottom: 1px solid #e92230;
  width: 50%;
  margin: 0px auto;
}
.login_btn a {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  color: #2b368a;
}
.login_btn a:focus {
  text-decoration: underline rgb(233, 34, 48);
  text-decoration-thickness: 3px;
}
.menu_inner ul.nav.navbar-nav > li.active > a {
  color: #5bc2e7;
}
.menu_inner ul.nav.navbar-nav > li.active > a svg path {
  stroke: #5bc2e7;
}
.menu_inner ul.nav.navbar-nav li:not(:last-child) {
  margin-bottom: 12px;
}
.menu_inner ul.nav.navbar-nav > li > a svg {
  flex: 0 0 30px;
  height: 30px;
  margin-right: 10px;
}
.foot_menu {
  padding-top: 2em;
}
.foot_menu p {
  margin: 0;
  color: #5bc2e7;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}
.foot_menu a {
  margin-bottom: 20px;
  display: inline-block;
}
.menu_inner ul.nav.navbar-nav > li.active > a svg path[fill="#fff"] {
  fill: #5bc2e7;
  stroke: unset;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@media only screen and (max-width: 500px) {
  .score-container {
    padding: 2px 15px 2px 15px;
  }

  /* .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    padding-right: 0px;
    padding-left: 0px;
  } */
  .cus-game a {
    font-size: 16px;
  }
  .cus-btn-blue {
    font-size: 20px;
  }

  .cus-header {
    margin: 15px 0;
    font-size: 20px;
  }
  .intro-logo {
    margin-top: 40%;
  }
  .cus-game h4 {
    font-size: 17px;
    font-family: "Roboto";
    margin: 12px 0 22px;
    display: block;
  }
  .container.white-bg {
    box-shadow: unset;
    background: #fff;
  }
  .blue-bg {
    width: 82%;
  }

  .container {
    width: 100%;
  }
  .contineu_img img {
    margin-left: 20px;
  }
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding: 5px 5px !important;
}
