.container {
    min-height: 100vh;
    width: 470px;
    font-family: "Roboto", sans-serif;
    background-image: linear-gradient(#5ec3e7, #fff);
    position: relative;
  }
  
  .success {
      text-align: center;
      position: relative;
      top: 37%;
    }
  
.main{
    /* padding: 2rem; */
    position: absolute;
    top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}


.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.logo img{
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    box-shadow: rgb(248 248 250) 0px 50px 100px -20px, rgb(251 250 250) 10px 30px 60px -30px, rgb(248 250 251) 10px -2px 6px 0px inset;
}

.message_box_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.message_box{
    width: 100%;
    border: 1px solid #CAEBF7;
    border-left: 4px solid #5ec3e7;
    padding: 20px;
    border-radius: 4px;
    background-color: #CAEBF7;
    backdrop-filter: blur(200px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.message{
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-family: 'Inter',sans-serif;
    padding: 0;
    margin: 0;
}