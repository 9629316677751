.modal_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70vh;
    /* height: 450px; */
    width: 50%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.8);
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    overflow: scroll;
    padding-bottom: 20px;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  
  .modal_container.modal_show {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
  }
  

.modal_sub_container{
    padding: 0 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 4rem;
}

.icon_style{
    color: red;
    width: 30rem;
    height: 15rem;
}

.modal_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}

.main_heading p{
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 2rem;
    color: black;
    background: linear-gradient(45deg,rgba(0,0,0,1) 0%,rgb(243, 13, 13));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.paragraph p{
    background: linear-gradient(to left,rgba(0,0,0,1),rgb(243, 13, 13));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}

.btn_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.confirm_btn{
    width: 20rem !important;
    height: 6rem !important;
    background: linear-gradient(45deg,rgba(0, 36, 83, 1) 0%,rgb(17, 0, 95));
    transition: all 0.4ms;
}

.confirm_btn:hover{
    background: linear-gradient(180deg,rgba(0, 36, 83, 1) 0%,rgb(17, 0, 95));
}

.cancel_btn{
    background: linear-gradient(45deg,rgb(148, 148, 148) 0%,rgb(99, 99, 99));
    width: 20rem !important;
    height: 6rem !important;
}

.cancel_btn:hover{
    background: linear-gradient(180deg,rgb(148, 148, 148) 0%,rgb(99, 99, 99));
}

@media screen and (max-width:800px) {
    .modal_container{
        width: 80%;
    }
    .cancel_btn{
        width: 10rem !important;
        height: 4rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
    }
    .confirm_btn{
        width: 10rem !important;
        height: 4rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
    }
}