body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #2c3e50;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* overflow: hidden; */

}

div#root {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

/* .overlay, div#root {
    height: 100vh !important ;
} */

.container {
    max-width: 80%;
    background-color: #ecf0f1;
    /* padding: 40px; */
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    opacity: 0;
    animation: fadeIn 0.8s ease-in-out forwards;
    z-index: 2; /* Place it above the overlay */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}

h2 {
    color: #3498db;
    margin-bottom: 20px;
}

p {
    color: #555;
    font-size: 16px;
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.button-container button {
    background-color: #e74c3c;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-container button:hover {
    background-color: #c0392b;
}

/* Media Query for Responsiveness */
@media only screen and (max-width: 600px) {
    .container {
        max-width: 100%;
    }

    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .button-container button {
        margin-top: 10px;
    }
}
