.form_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1.4rem;
    flex-direction: column;
    padding: 1rem 2rem 1rem 2rem;
}

.input_container{
    position: relative;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.input_container p{
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translate(0,-50%);
    font-size: 2rem;
    font-weight: bold;
    color:rgb(65, 64, 64);
    padding: 14px 0 14px 0;
}

.input_container input{
    border: none;
    outline: none;
    background-color:white;
    outline: none;
    color:rgb(65, 64, 64);
    font-size: 2rem;
    margin-left:6.5rem;
    padding: 14px 0 14px 0px;
}

.input_container input::placeholder{
   text-transform: uppercase;
   color:rgb(65, 64, 64);
}

.btn{
    margin-top: 100px;
    width: 20rem;
    height:5rem;
    padding: 1rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    background: linear-gradient(45deg,rgb(216, 20, 20) 0%,rgb(218, 63, 2));
}


.footer_logo{
    width: 20rem;
    height: 5rem;
    margin: unset !important;
} 
.footer_container{
    width: 100% !important;
    margin-top: 110px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.dropdown_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.selector{
    width: 25rem;
}

.wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: -30px;
}

.message_primary{
    width:300px;
    text-align: center;
    color: #2A3382;
    font-size: 22px;
    font-weight: 900;
    padding: 0;
    margin: 0;
}

.message_secondary{
    width:300px;
    text-align: center;
    color: #2A3382;
    font-size: 22px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.switch_btn{
    width: 326px;
    padding: 10px 0 10px 0;
    background: #2B368A;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Inter',sans-serif;
    text-align: center;
    border-radius: 8px;
}

.stay_with_btn{
    width: 326px;
    padding: 10px 0 10px 0;
    background: #E92230;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Inter',sans-serif;
    text-align: center;
    border-radius: 8px;
}

.back_btn{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #00063F;
    font-size: 2.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f1f1f1;
    box-shadow: 0 0 0 1px #333;
    align-self: flex-start;
    cursor: pointer;
}

@media screen and (max-width:800px){
    .selector{
        width: 25rem;
    }
}

@media screen and (max-width:800px){
    .input_container p{
       font-size: 1.8rem;
    }

    .form_container input{
        font-size: 1.8rem;
    }
    
    .form_container input::placeholder{
        font-size: 1.8rem;
     }
}

@media screen and (max-width:400px){
    .input_container p{
       font-size: 1.6rem;
    }

    .form_container input{
        font-size: 1.6rem;
        margin-left: 6rem;
    }
    
    .form_container input::placeholder{
        font-size: 1.6rem;
     }
}

@media screen and (min-height:700px){
    .footer_container{
        /* position: fixed; */
        bottom: 1rem;
    }
}