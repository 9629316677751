.unsub_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.unsub_btn{
    padding: 10px;
    width: 200px;
    background-color: #ee1515;
    transition: all 0.2s;
}

.unsub_btn:hover{
    background-color: #db0a0a;
}

.unsub_btn:active{
    transform: scale(1.1);
}