/* login.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #2c3e50;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #2c3e50;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
} */

/* .screen-1 {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
} */

  .screen-1 {
    width: 800px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    margin: 10px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.dropdown .css-13cymwt-control {
  height: 60px;
}

.logo {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.email, .password {
  margin-bottom: 20px;
}

.email
{
  width:70%;
}
/* label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
} */

label {
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.sec-2 {
  position: relative;
}

ion-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}

/* input {
  width: calc(100% - 22px);
  padding: 10px;
  padding-left: 40px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
} */

input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  padding: 20px;
  color: #333;
}

/* button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
} */

button {
  width: 100%;
  padding: 15px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}
input::placeholder {
  font-size: 18px;
}

button:hover {
  background-color: #45a049;
}

.footer {
  margin-top: 20px;
  font-size: 14px;
  color: #555;
}
.css-1jqq78o-placeholder {
  font-size: 18px;
}

.footer span {
  margin-right: 20px;
  cursor: pointer;
  color: #007bff;
}
.css-t3ipsp-control {
  height: 60px;
}
/* .dropdown {
  margin-bottom: 20px;
} */
.dropdown {
  margin: 20px 0px;
}

/* Customize the react-select component */
.react-select__control {
  width: 100%;
}

.react-select__placeholder {
  color: #aaa;
}

/* For mobile devices only */
@media only screen and (max-width: 767px) {
  body {
      overflow-x: auto; /* Hide horizontal scrollbar on mobile */
  }
  .screen-1 {
    height: auto;
    margin: 175px 10px 0px;
}
}

/* Add any additional styles as needed */
