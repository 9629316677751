@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.container {
  min-height: 100vh;
  width: 470px;
  font-family: "Roboto", sans-serif;
  background-image: linear-gradient(#5ec3e7, #fff);
  position: relative;
}

.success {
    text-align: center;
    position: relative;
    top: 37%;
  }

  .verification {
    top: 15%;
  }

  .tabs{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.tab1 p{
    color:#000855;
    font-size: 2rem;
    width: 100%;
    font-weight: bolder;
    padding:0 1rem 0 1rem;
    border-bottom: 2px solid #e43500;
    cursor: pointer;
}

.tab2 p{
    color:#001d9e;
    width: 100%;
    font-size: 2rem;
    font-weight: bold;
    padding:0 1rem 0 1rem;
    border-bottom: 2px solid #c1c1c1;
    cursor: pointer;
}

  @media only screen and (max-width: 500px) {
    .container.white-bg {
      box-shadow: unset;
      background: #fff;
    }
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      display: block;
      overflow: hidden;
    }
  }
  