@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.container {
  height: 100vh;
  width: 470px;
  font-family: "Roboto", sans-serif;
  background-image: linear-gradient(#5ec3e7, #fff);
}
.center_logo {
    text-align: center;
    display: block;
    width: 100%;
    margin: 0 auto;
    top: 45%;
    position: relative;
  }

  .logo{
    width: 32rem;
    height: auto;
    object-fit: contain;
  }

  @media only screen and (max-width: 500px) {
    .container.white-bg {
      box-shadow: unset;
      background: #fff;
    }
    .container {
      width: 100%;
    }
  }